import React from 'react';
import {
  ContainerInform,
  DivBlocks,
} from '../administrativeServices/InformaationAdministrativeService/styles';
import ContainerContent from '../Container';

export default function AdministrativeServicesComponent({ title, content }) {
  return (
    <ContainerContent
      name={title}
      content={
        <DivBlocks>
          <ContainerInform>
            <h3>
              УП «МИНГАЗ» осуществляет административные процедуры в соответствии с{' '}
              <a href={'https://etalonline.by/document/?regnum=h10800433&q_id=5918245'}>
                Законом Республики Беларусь от 28 октября 2008 г. № 433-З
              </a>{' '}
              «Об основах административных процедур».
            </h3>
            {content}
            <div>
              <p>
                [1] Нумерация административных процедур соответствует нумерации таких
                административных процедур в перечне административных процедур, осуществляемых
                государственными органами и иными организациями по заявлениям граждан, утвержденном
                <a href={'https://etalonline.by/document/?regnum=p31000200&q_id=5917741'}>
                  Указом Президента Республики Беларусь от 26 апреля 2010 г. № 200
                </a>
                .
              </p>
              <p>
                [2] Заявление подается в произвольной форме на белорусском и (или) русском языках и
                должно содержать:{' '}
              </p>
              <ul>
                <li>наименование уполномоченного органа, в который подается заявление;</li>
                <li>
                  сведения о заинтересованном лице: фамилия, собственное имя, отчество (если таковое
                  имеется), место жительства (место пребывания);
                </li>
                <li>
                  наименование административной процедуры, за осуществлением которой обращается
                  заинтересованное лицо;
                </li>
                <li>
                  перечень документов и (или) сведений (при их наличии), представляемых вместе с
                  заявлением заинтересованного лица;
                </li>
                <li>подпись гражданина либо подпись представителя заинтересованного лица.</li>
              </ul>
            </div>
          </ContainerInform>
        </DivBlocks>
      }
    />
  );
}
