import styled from 'styled-components';

export const BtnOpenInform = styled.button`
  color: #0d4475;
  font-size: 18px;
  font-weight: 500;
  width: 220px;
  text-align: center;
  border-radius: 7px;
  text-decoration: none;
  display: flex;
  border: none;
  justify-content: center;
  align-items: center;
  height: 40px;
  margin: 2% auto;
  z-index: 2;
  cursor: pointer;
`;
