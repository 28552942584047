export const data = [
  {
    id: 1,
    cardImg: 1,
    cardDesc: 'Оплата за газ',
    link: 'services/650d480b011c5298afa5f26f',
  },
  {
    id: 2,
    cardImg: 2,
    cardDesc: 'Техническое обслуживание',
    link: 'services/6391be07e9cc88452c22bee8',
  },
  { id: 3, cardImg: 3, cardDesc: 'Вакансии', link: 'company/career' },
  {
    id: 4,
    cardImg: 7,
    cardDesc: 'Обмен баллонов',
    link: 'services/639ab8d8d48d019c9596ee9b',
  },
  { id: 5, cardImg: 5, cardDesc: 'Прейскурант', link: 'residents/price' },
  {
    id: 6,
    cardImg: 6,
    cardDesc: 'Часто задаваемые вопросы',
    link: 'feedback/question-answer/63930a954fe167e2981cc2e1',
  },
];
