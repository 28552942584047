import styled from 'styled-components';
export const LinkToCallRepresentative = styled.a`
  font-style: normal;
  line-height: 37px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-transform: uppercase;
  font-size: 24px;
  justify-content: center;
  letter-spacing: 0.1em;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  color: #0d4475;
`;
