export const routers = [
  { id: 1, router: 'services', name: 'услуги' },
  { id: 2, router: 'services-legal-entities', name: 'услуги для бизнеса' },
  { id: 3, router: 'feedback/online-application', name: 'Оставить заявку' },
  { id: 4, router: 'company/history', name: 'История предприятия' },
  { id: 5, router: 'company/branches', name: 'филиалы' },
  { id: 6, router: 'company/documentation', name: 'сертификаты, лицензии, свидетельства' },
  { id: 7, router: 'company/gratitude', name: 'благодарности и награды' },
  { id: 8, router: 'feedback/electronic-appeal', name: 'обращение граждан' },
  { id: 9, router: 'regulatory-documents/Законы', name: 'регламинтирующие документы' },
  { id: 10, router: 'residents/price', name: 'прейскурант' },
  { id: 11, router: 'residents/price', name: 'Прейскурант' },
];
