import React from 'react';
import { PageSection } from '../GeneralContactInform/styles';
import { DivMap, Row } from '../styles';
import HeaderConcats from '../headerContacts';
import { Table, Tbody } from '../../feedback/receptionOfCitizens/styles';
import ContainerContent from '../../../components/Container';

export default function PhoneLineServices() {
  return (
    <ContainerContent
      name={'Основная информация'}
      content={
        <>
          <Row>
            <HeaderConcats width={'s'} />
            <PageSection>
              <Table style={{ height: '70%', margin: 'auto 0' }}>
                <Tbody className={'general'}>
                  <tr>
                    <td>Контакт-центр УП «МИНГАЗ»</td>
                    <td>
                      <a style={{ fontWeight: 'bold', fontSize: '22px' }} href={'tel:162'}>
                        162
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>График работы центра обслуживания клиентов</td>
                    <td>
                      г. Минск, ул. П. Бровки, д. 1 ежедневно с 08:00 до 20:00, кроме праздничных
                      дней <br />
                      г. Заславль, ул. Советская, 79 09:00-18:00 (пн-чт и 3-я суббота месяца),
                      09:00-16:45 (пт.), обед: 13:00-13:45
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <i>Контакт-центр Центра обслуживания клиентов "Мингаз"*</i> <br /> в случае
                      нахождения за пределами г. Минска и Минского района
                    </td>
                    <td>
                      <a href={'tel:299-28-40'}> 299-28-40</a>
                    </td>
                  </tr>
                  <tr>
                    <td>«Горячая линия»</td>
                    <td>
                      <a href={'tel:+375(17)-299-28-70'}> +375(17)-299-28-70</a>, <br />
                      08:00-17:00 (пн-чт), 08:00-15:45 (пт.), обед: 12:00-12:45
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Прямая телефонная линия проводится каждую <br /> последнюю пятницу месяца с
                      11.00 до 12.00
                    </td>
                    <td>
                      <a href={'tel:8017-299 28 80'}>+375(17)-299-28-80</a>
                    </td>
                  </tr>
                </Tbody>
              </Table>
            </PageSection>
          </Row>
          <DivMap>
            <div style={{ position: 'relative', overflow: 'hidden' }}>
              <a
                href="https://yandex.by/maps/org/tsentr_obsluzhivaniya_kliyentov_up_mingaz/51103648001/?utm_medium=mapframe&utm_source=maps"
                style={{ color: '#eee', fontSize: '12px', position: 'absolute', top: '0px' }}
              >
                Центр обслуживания клиентов УП Мингаз
              </a>
              <iframe
                src="https://yandex.by/map-widget/v1/?ll=27.593782%2C53.919477&mode=search&oid=51103648001&ol=biz&z=18.96"
                width="560"
                height="400"
                allowFullScreen="true"
                style={{ position: 'relative' }}
                title={'карта'}
              />
            </div>
          </DivMap>
        </>
      }
    />
  );
}
