export const links = [
  {
    idService: 1,
    imgService: 12,
    nameService: 'Административные процедуры',
    linkService: '/services/administrative-services',
  },
  {
    idService: 8,
    imgService: 16,
    nameService: 'Прейскурант цен',
    linkService: '/residents/price',
  },
];
