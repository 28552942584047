import styled from 'styled-components';

export const SelectDiv = styled.select`
  padding: 0.7rem;
  border-radius: 10px;
  border: 1px solid #0d4475;
  option {
    color: rgb(118, 118, 118);
  }
`;

export const DivSelect = styled.div`
  width: 88%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  margin: 1% auto;
  @media (max-width: 350px) {
    width: 280px;
  }
`;
