export const data = [
  {
    id: 1,
    name: 'Стольградный Газовщик № 5',
    data: 'сентябрь 2011 г.',
    link: 'five',
  },
  {
    id: 2,
    name: 'Стольградный Газовщик № 6',
    data: 'декабрь 2011 г.',
    link: 'six',
  },
  {
    id: 3,
    name: 'Стольградный Газовщик № 7',
    data: 'март 2012 г.',
    link: 'seven',
  },
  {
    id: 4,
    name: 'Стольградный Газовщик № 8',
    data: 'май 2012 г.',
    link: 'eight',
  },
  {
    id: 5,
    name: 'Стольградный Газовщик № 9',
    data: 'сентябрь 2012 г.',
    link: 'nine',
  },
  {
    id: 6,
    name: 'Стольградный Газовщик № 10',
    data: 'декабрь 2012 г.',
    link: 'ten',
  },
  {
    id: 7,
    name: 'Стольградный Газовщик № 11',
    data: 'март 2013 г.',
    link: 'eleven',
  },
  {
    id: 8,
    name: 'Стольградный Газовщик № 12',
    data: 'май 2013 г.',
    link: 'twelve',
  },
  {
    id: 9,
    name: 'Стольградный Газовщик № 13',
    data: 'август 2013 г.',
    link: 'thirteen',
  },
  {
    id: 10,
    name: 'Стольградный Газовщик № 14',
    data: 'ноябрь 2013 г.',
    link: 'fourteen',
  },
  {
    id: 11,
    name: 'Стольградный Газовщик № 15',
    data: 'декабрь 2013 г.',
    link: 'fifteen',
  },
  {
    id: 12,
    name: 'Стольградный Газовщик № 16',
    data: 'март 2014 г.',
    link: 'sixteen',
  },
  {
    id: 13,
    name: 'Стольградный Газовщик № 17',
    data: 'май 2014 г.',
    link: 'seventeen',
  },
  {
    id: 14,
    name: 'Стольградный Газовщик № 18',
    data: 'октябрь 2014 г.',
    link: 'eighteen',
  },
  {
    id: 15,
    name: 'Стольградный Газовщик № 19',
    data: 'декабрь 2014 г.',
    link: 'nineteen',
  },
  {
    id: 16,
    name: 'Столичный Газовик № 20',
    data: 'апрель 2015 г.',
    link: 'twenty',
  },
  {
    id: 19,
    name: 'Столичный Газовик № 23',
    data: 'декабрь 2015 г.',
    link: 'twenty_three',
  },
  {
    id: 20,
    name: 'Столичный Газовик № 24',
    data: 'апрель 2016 г.',
    link: 'twenty_four',
  },
  {
    id: 21,
    name: 'Столичный Газовик № 25',
    data: 'сентябрь 2016 г.',
    link: 'twenty_four',
  },
  {
    id: 22,
    name: 'Столичный Газовик № 26',
    data: 'декабрь 2016 г.',
    link: 'twenty_seven',
  },
  {
    id: 23,
    name: 'Столичный Газовик № 27',
    data: 'март 2017 г.',
    link: 'twenty_seven',
  },
  {
    id: 24,
    name: 'Столичный Газовик № 28',
    data: 'май 2017 г.',
    link: 'twenty_eight',
  },
  {
    id: 25,
    name: 'Столичный Газовик № 29',
    data: 'август 2017 г.',
    link: 'twenty_nine',
  },
  {
    id: 26,
    name: 'Столичный Газовик № 30',
    data: 'декабрь 2017 г.',
    link: 'thirty',
  },
  {
    id: 27,
    name: 'Столичный Газовик № 31',
    data: 'март 2018 г.',
    link: 'thirty_one',
  },
  {
    id: 28,
    name: 'Столичный Газовик № 32',
    data: 'май 2018 г.',
    link: 'thirty_two',
  },
  {
    id: 29,
    name: 'Выпуск, посвященный 65-летию УП "МИНГАЗ"',
    data: 'декабрь 2022 г.',
    link: 'last_gazeta',
  },
  {
    id: 30,
    name: 'Выпуск, посвященный 65-летию УП "МИНГАЗ"',
    data: 'октябрь 2022 г.',
    link: 'last',
  },
];
