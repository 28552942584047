export const links = [
  {
    id: 1,
    link: '/company/branches/ogonek',
    name: 'Оздоровительный комплекс "Огонек"',
    cardImg: 65,
  },
  {
    id: 2,
    link: '/company/branches/Bubni',
    name: 'Филиал "Бубны"',
    cardImg: 64,
  },
  {
    id: 3,
    link: '/company/branches/TBZ',
    name: 'Филиал "Торфобрикетный завод "Сергеевическое"',
    cardImg: 63,
  },
];
