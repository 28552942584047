export const linksForBusiness = [
  {
    id: 1,
    link: '/services-legal-entities',
    name: 'Услуги',
    cardImg: 8,
  },
  {
    id: 2,
    link: '/feedback/electronic-appeal',
    name: 'Обращение юридических лиц',
    cardImg: 1,
  },
  {
    id: 3,
    link: '/tenders',
    name: 'Тендеры',
    cardImg: 11,
  },
  {
    id: 4,
    link: '/feedback/question-answer/63930a954fe167e2981cc2e1',
    name: 'Часто задаваемые вопросы',
    cardImg: 14,
  },
  {
    id: 5,
    link: '/services-legal-entities/administrative-services-legal/',
    name: 'Административные процедуры',
    cardImg: 12,
  },
  {
    id: 6,
    link: '/regulatory-documents-for-business/Законы',
    name: 'Регламентирующие документы',
    cardImg: 9,
  },
  { id: 7, link: '/illiquid-assets', name: 'Реализация неликвидов', cardImg: 1 },
];
