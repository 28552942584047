export const photo = [
    { id: 1, img: '1' },
    { id: 2, img: '2' },
    { id: 3, img: '3' },
    { id: 4, img: '4' },
    { id: 5, img: '5' },
    { id: 6, img: '6' },
    { id: 7, img: '7' },
    { id: 8, img: '8' },
    { id: 9, img: '9' },
    { id: 10, img: '10' },
    { id: 11, img: '11' },
    { id: 12, img: '12' },
    { id: 13, img: '13' },
    { id: 14, img: '14' },
    { id: 15, img: '15' },
    { id: 16, img: '16' },
    { id: 17, img: '17' },
]