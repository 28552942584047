export const links = [
  {
    id: 1,
    link: '/services',
    name: 'Услуги',
    cardImg: 8,
  },
  {
    id: 2,
    link: '/services/administrative-services',
    name: 'Административные процедуры',
    cardImg: 12,
  },
  {
    id: 3,
    link: '/feedback/online-application',
    name: 'Онлайн заявки',
    cardImg: 11,
  },
  {
    id: 4,
    link: '/regulatory-documents/Законы',
    name: 'Регламентирующие документы',
    cardImg: 9,
  },
  {
    id: 5,
    link: '/feedback/question-answer/63930a954fe167e2981cc2e1',
    name: 'Часто задаваемые вопросы',
    cardImg: 14,
  },
  {
    id: 6,
    link: '/feedback/electronic-appeal',
    name: 'Обращение граждан',
    cardImg: 1,
  },
  {
    id: 7,
    link: '/feedback/leave-feedback',
    name: 'Оставить отзыв',
    cardImg: 15,
  },
  {
    id: 8,
    link: '/posts',
    name: 'Важно знать, безопасность',
    cardImg: 15,
  },
];
