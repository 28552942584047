import ContainerContent from '../Container';
import React from 'react';
import { Column, Container } from './styles';
import { Links } from '../Price/styles';

export default function Laboratory() {
  return (
    <ContainerContent
      name={'Диагностические услуги'}
      content={
        <>
          <Links>
            <p>Лаборатория СНКИиТД</p>
          </Links>
          <Container>
            <Column>
              <img style={{ width: '200px' }} src={require('./7.png')} alt={'7'} />
              <p>
                Производственное республиканское предприятие «МИНГАЗ» Служба неразрушающего контроля
                измерений и технической диагностики
              </p>
              <p>Контактный номер: +375 (17) 299-28-46 Почтовый адрес: snk@mingas.by</p>
              <img src={require('./8.png')} alt={'8'} />
              <p>
                <strong>Электрофизические измерения</strong>
              </p>
              <p>
                Участок электрофизических измерений выполняет такие задачи как: - проверка
                соединений заземлителей с заземляемыми элементами; - испытания цепи фаза-нуль»; -
                проверка сопротивления заземляющих устройств; - измерение сопротивления изоляции;
              </p>
              <img src={require('./9.png')} alt={'9'} />
              <p>
                - испытание автоматических выключателей в электроустановках до 1000 В; - измерение
                удельного сопротивления грунта в полевых (трассовых) условиях; - проверка токов и
                времени срабатывания УЗО дифференциальных автоматов; - испытание индивидуальных
                средств защиты, применяемых в электроустановках до 1000В (перчатки, боты, галоши,
                диэлектрический инструмент)
              </p>
            </Column>
            <Column>
              <p>
                <strong>Цифровой радиаграфический контроль сварных соединений</strong>
              </p>
              <p>
                Цифровая радиография – технология рентгенографического контроля, при которой
                носителем рентгеновского изображения выступает не плёнка, а плоскопанельный детектор
                (DDA система).
              </p>
              <img src={require('./1.png')} alt={'1'} />
              <p>
                При выполнении контроля используется автоматизированная рентгенометрическая система
                «БАРС» и цифровой рентгеновский программно-аппаратный комплекс «БеРКУТ»
                (предназначен для диаметров от 16 мм, до 530 мм.).
              </p>
              <img src={require('./2.png')} alt={'2'} />
              <p>
                <strong>Служба выполняет такие виды работ как: телеинспекция</strong>
              </p>
              <p>
                Теледиагностика трубопровода осуществляется с помощью телеинспекционного комплекса.
                Он состоит из фургона, полностью укомплектованного всем необходимым оборудованием и
                приспособлениями для осуществления обследования сетей.
              </p>
              <img src={require('./3.png')} alt={'3'} />
              <p>
                Видеокамера с широким углом обзора, 3-кратным зумом, высоким разрешением, снабженная
                светодиодным освещением, позволяет качественно фиксировать состояние внутренней
                поверхности трубопровода и наглядно показать состояние до осуществления работ и
                после. Благодаря гусеничному ходу комплекс имеет большой запас по прохождению
                всевозможных препятствий внутри трубы.
              </p>
            </Column>
            <Column>
              <p>
                <strong>Неразрушающий контроль качества</strong>
              </p>
              <p>
                Служба проводит следующие методы контроля: визуальный метод контроля (VT),
                ультразвуковая толщинометрия (UT), радиографический контроль (RT), капиллярный
                контроль (PT), ультразвуковая дефектоскопия (UD), измерение твердости (IT).
              </p>
              <p>
                <strong>Оптимизация сварочного процесса и подготовка сварщиков к аттестации</strong>
              </p>
              <p>
                Применение цифровой радиографии на месте производства сварочных работ позволяет
                незамедлительно выявлять ошибки, при их наличии, в технологии сварки.
              </p>
              <img src={require('./4.png')} alt={'4'} />
              <p>
                Все дефекты разбираются совместно со сварщиками, что позволяет наладить процесс
                производства работ и исключить возникновение дефектов в дальнейшем.
              </p>
              <img src={require('./5.png')} alt={'5'} />
              <p>
                Мы предупредим любую поломку, своевременно проведя осмотр, и вы можете продлить срок
                службы трубопровода на десятилетия. Мы просматриваем газопроводы, нефтепроводы,
                водопроводы и канализационные трубы диаметром от 150 мм до 2000 мм. Максимальная
                длина кабеля:1000 метров, Максимальная глубина залегания сетей 60 метров
              </p>
              <img src={require('./6.png')} alt={'6'} />
              <p>
                УП «МИНГАЗ», служба неразрушающего контроля, измерений и технической диагностики,
                220037, г.Минск, ул.Ботаническая, 11/4, каб.8, каб.10. snk@mingas.by
              </p>
            </Column>
          </Container>
        </>
      }
    />
  );
}
