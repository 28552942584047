export const documents = [
  {
    GeneralId: 1,
    name: 'Сертификаты',
    inform: [{ id: 1, img: '67' }],
  },
  {
    GeneralId: 4,
    name: 'Сертификаты',
    inform: [
      { id: 1, img: '22' },
      { id: 2, img: '23' },
      { id: 3, img: '24' },
      { id: 4, img: '25' },
      { id: 5, img: '26' },
      { id: 6, img: '27' },
      { id: 7, img: '28' },
      { id: 8, img: '29' },
    ],
  },
  {
    GeneralId: 5,
    name: 'Сертификаты',
    inform: [
      { id: 1, img: '70' },
      { id: 2, img: '71' },
      { id: 3, img: '72' },
      { id: 4, img: '73' },
      { id: 5, img: '74' },
      { id: 6, img: '75' },
      { id: 7, img: '76' },
      { id: 8, img: '77' },
    ],
  },
  {
    GeneralId: 6,
    name: 'Сертификаты',
    inform: [
      { id: 1, img: '30' },
      { id: 2, img: '31' },
      { id: 3, img: '32' },
      { id: 4, img: '33' },
    ],
  },
  {
    GeneralId: 7,
    name: 'Сертификаты',
    inform: [
      { id: 1, img: '11' },
      { id: 2, img: '12' },
      { id: 3, img: '13' },
      { id: 4, img: '14' },
      { id: 5, img: '15' },
      { id: 6, img: '16' },
      { id: 7, img: '17' },
    ],
  },
  {
    GeneralId: 8,
    name: 'Свидетельства',
    inform: [
      { id: 1, img: '34' },
      { id: 2, img: '35' },
    ],
  },
  {
    GeneralId: 9,
    name: 'Свидетельства',
    inform: [
      { id: 1, img: '36' },
      { id: 2, img: '37' },
    ],
  },

  {
    GeneralId: 10,
    name: 'Лицензии',
    inform: [
      { id: 1, img: '38' },
      { id: 2, img: '39' },
      { id: 3, img: '40' },
      { id: 4, img: '41' },
    ],
  },
  {
    GeneralId: 11,
    name: 'Лицензии',
    inform: [
      { id: 1, img: '42' },
      { id: 2, img: '43' },
      { id: 3, img: '44' },
    ],
  },
  {
    GeneralId: 12,
    name: 'Лицензии',
    inform: [
      { id: 1, img: '45' },
      { id: 2, img: '46' },
      { id: 3, img: '47' },
      { id: 4, img: '48' },
      { id: 5, img: '49' },
      { id: 6, img: '50' },
    ],
  },
  {
    GeneralId: 14,
    name: 'Лицензии',
    inform: [
      { id: 1, img: '55' },
      { id: 2, img: '56' },
      { id: 3, img: '57' },
      { id: 4, img: '58' },
    ],
  },
  {
    GeneralId: 15,
    name: 'Лицензии',
    inform: [
      { id: 1, img: '59' },
      { id: 2, img: '60' },
      { id: 3, img: '61' },
    ],
  },
  {
    GeneralId: 16,
    name: 'Аттестаты',
    inform: [
      { id: 1, img: '1' },
      { id: 2, img: '2' },
      { id: 3, img: '3' },
      { id: 4, img: '4' },
      { id: 5, img: '5' },
      { id: 6, img: '6' },
      { id: 7, img: '7' },
      { id: 8, img: '8' },
    ],
  },
  // {
  //   GeneralId: 17,
  //   name: 'Аттестаты',
  //   inform: [
  //     { id: 1, img: '78' },
  //     { id: 2, img: '79' },
  //     { id: 3, img: '80' },
  //   ],
  // },
  {
    GeneralId: 18,
    name: 'Аттестаты',
    inform: [{ id: 1, img: '62' }],
  },
  {
    GeneralId: 19,
    name: 'Аттестаты',
    inform: [{ id: 1, img: '63' }],
  },
  {
    GeneralId: 20,
    name: 'Аттестаты',
    inform: [{ id: 1, img: '64' }],
  },
  {
    GeneralId: 21,
    name: 'Аттестаты',
    inform: [{ id: 1, img: '65' }],
  },
  {
    GeneralId: 22,
    name: 'Аттестаты',
    inform: [
      { id: 1, img: '81' },
      { id: 2, img: '82' },
      { id: 3, img: '83' },
      { id: 4, img: '84' },
      { id: 5, img: '85' },
      { id: 6, img: '86' },
      { id: 7, img: '87' },
      { id: 8, img: '88' },
      { id: 9, img: '89' },
      { id: 10, img: '90' },
    ],
  },
  {
    GeneralId: 23,
    name: 'Сертификаты',
    inform: [{ id: 1, img: '66' }],
  },
  {
    GeneralId: 26,
    name: 'Сертификаты',
    inform: [
      { id: 1, img: '18' },
      { id: 2, img: '19' },
      { id: 3, img: '20' },
      { id: 4, img: '21' },
    ],
  },
  {
    GeneralId: 25,
    name: 'Сертификаты',
    inform: [
      { id: 1, img: '91' },
      { id: 2, img: '92' },
      { id: 3, img: '93' },
      { id: 4, img: '94' },
      { id: 5, img: '95' },
      { id: 6, img: '96' },
    ],
  },
  {
    GeneralId: 24,
    name: 'Свидетельства',
    inform: [
      { id: 1, img: '68' },
      { id: 2, img: '69' },
    ],
  },
  {
    GeneralId: 27,
    name: 'Аттестаты',
    inform: [
      { id: 1, img: '97' },
      { id: 2, img: '98' },
      { id: 3, img: '99' },
    ],
  },
];
