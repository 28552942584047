import React from 'react';
import { LinksContainer, Logo } from '../header/styles';
import HeaderLogo from '../../assets/png/mingaz_logo_white.webp';
import { Contacts, Telephons, FooterContainer } from './styles';
import ButtonFun from '../button';

import { Prizes } from '../../pages/Home/Content/styles';
import { IoLogoInstagram, IoLogoYoutube } from 'react-icons/io';
import { NavLink } from 'react-router-dom';
export default function Footer({ classname }) {
  return (
    <FooterContainer className={classname}>
      <LinksContainer>
        <NavLink to="/">
          <Logo style={{ width: '70px', height: '70px' }} src={HeaderLogo} />
        </NavLink>
        <Prizes>
          <img alt={'image1'} src={require(`../../assets/prizes/1.webp`)} />
          <img alt={'image2'} src={require(`../../assets/prizes/2.webp`)} />
          <img alt={'image3'} src={require(`../../assets/prizes/3.webp`)} />
          <img alt={'image4'} src={require(`../../assets/prizes/4.webp`)} />
          <img alt={'image5'} src={require(`../../assets/prizes/5.webp`)} />
        </Prizes>
      </LinksContainer>
      <Contacts>
        <Telephons>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <p>Смотри нас в </p>
            <a
              title={'Инстаграм'}
              href={'https://instagram.com/mingas.by?igshid=YmMyMTA2M2Y='}
              target={'_blank'}
              rel="noreferrer"
            >
              <IoLogoInstagram style={{ width: '40px', height: '40px' }} />
            </a>
            <a
              title={'Telegram'}
              href={'https://t.me/mingasby'}
              target={'_blank'}
              rel="noreferrer"
              style={{ margin: '0 8px 0 4px' }}
            >
              <img
                alt={'telegram'}
                src={require('../../assets/png/telegram.png')}
                style={{ width: '32px', height: '32px' }}
              />
            </a>
            <a
              title={'YouTube'}
              href={'https://www.youtube.com/@user-ui4mh4wv6t'}
              target={'_blank'}
              rel="noreferrer"
            >
              <IoLogoYoutube style={{ width: '36px', height: '40px' }} />
            </a>
          </div>
          <ButtonFun href={'/contacts/phone-services'} infoButton={'Телефоны для связи'} />
          <p>
            Контакт-центр УП «МИНГАЗ» тел. <a href={'tel:162'}>162</a>
          </p>
          <div style={{ marginBottom: '0.5rem' }}>
            <a
              type={'download'}
              href={'https://back.mingas.by/admin/upload/files/1738046979037-Политика_Мингаз_для сайта.pdf'}
            >
              Политика обработки персональных данных УП "МИНГАЗ"
            </a>
          </div>
          <a
            type={'download'}
            href={
              'https://back.mingas.by/admin/upload/files/1737614292790-Политика_куки_Мингаз_для сайта.pdf'
            }
          >
            Политика обработки файлов cookie УП «МИНГАЗ»
          </a>
        </Telephons>
        <Telephons>
          <ButtonFun href={'/contacts/phone-services'} infoButton={'Контактная информация'} />
          <p>
            220037, г.Минск,{' '}
            <a href={'https://yandex.by/maps/-/CCUVf2cXKC'} target={'_blank'} rel="noreferrer">
              ул.Ботаническая, 11/1
            </a>
          </p>
          <p style={{ margin: '0' }}>
            Факс: <a href={'tel:+375 (017) 366-36-33'}>+375 (017) 366-36-33</a> E-mail:
            <a href={'mailto:root@mingas.by'}>root@mingas.by</a>
          </p>
        </Telephons>
        <Telephons>
          <ButtonFun href={'/contacts/work-schedule'} infoButton={'График работы'} />
          <p>Понедельник – Четверг: с 8:00 до 17:00</p>
          <p>Пятница: с 8:00 до 15:45</p>
          <p>Обед с 12:00 до 12:45</p>
          <p>
            <a
              href={'https://mingas.by/contacts/phone-services'}
              target={'_blank'}
              rel="noreferrer"
            >
              График работы центра обслуживания клиентов
            </a>
          </p>
        </Telephons>
      </Contacts>
    </FooterContainer>
  );
}
